<template>
  <div>
    <div class="navbar" style="padding: 10px" v-if="isNavbarVisible">
      <template v-if="isNarrow">
        <div class="nav-bar">
          <div class="btn-menu">
            <button @click="toggleMenu" class="btn">
              <MenuIcon />
            </button>
            <SlideMenu ref="slideMenu"></SlideMenu>
          </div>
          <div>
            <img src="./assets/logo.png" alt="logo" width="250" height="30" />
          </div>
          <div style="width: 50px"></div>
        </div>
      </template>
      <template v-else>
        <Menu />
      </template>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SlideMenu from "./components/BurgerMenu2";
import MenuIcon from "vue-material-design-icons/Menu";
import Menu from "./components/Menu.vue";
import { connIp } from "./store/conn";
import Cookies from "js-cookie";

export default {
  components: {
    MenuIcon,
    SlideMenu,
    Menu,
  },
  data() {
    return {
      user: "",
      usser: "",
      isNarrow: false,
      connIp: connIp,
      err: "",
    };
  },
  computed: {
    ...mapState(["isNavbarVisible"]),
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    window.addEventListener("resize", this.checkWidth);
    this.checkWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkWidth);
  },
  methods: {
    checkWidth() {
      this.isNarrow = window.innerWidth < 768; // Adjust the width as per your requirement
    },
    toggleMenu() {
      this.$refs.slideMenu.toggleMenu();
    },
    getA() {
      var storedUser = JSON.parse(sessionStorage.getItem("user"));
      if (storedUser && storedUser.username) {
        this.user = storedUser.username;
      }
      const userCookie = Cookies.get("user");
      if (userCookie !== undefined) {
        const user = JSON.parse(userCookie);
        this.useername = user.username;
      } else {
        this.$router.push("/");
      }
    },
    logout() {
      this.isOpen = !this.isOpen;
      this.$store.dispatch("logout");
      this.$router.push("/");
    },
  },
  created() {
    this.getA();
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.navbars {
  cursor: pointer;
}

.navbar {
  background-color: var(--background-color);
  justify-content: space-between;
  display: flex;
}

.nav-bar {
  justify-content: space-between;
  display: flex;
  flex: 1;
}

.navbara {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.btn {
  border: 1px solid #333;
  border-radius: 10px;
}

.nav-name {
  top: 10;
  right: 10;
}
</style>