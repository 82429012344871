<template>
    <div class="error-page not-found">
      <h1>404</h1>
      <p>Stran ni bila najdena</p>
      <router-link to="/nadzornaPlosca">Nazaj na nadzorno ploščo</router-link>
    </div>
  </template>
  
  <style scoped>
  /* Add styling specific to the 404 page */
  </style>
  