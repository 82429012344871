import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import DashboardView from '../views/DashboardView.vue'
import TimeTrackingView from '../views/TimeTrackingView.vue'
import LogoutView from '../views/LogoutView.vue'
import NotFoundView from '../views/errors/NotFound.vue'
import GeneralErrorView from '../views/errors/General.vue'
import Cookies from 'js-cookie'

// const requireAuth = (to, from, next) => {
//   const user = sessionStorage.getItem('user') // Get user from storage

//   if (user) {
//     // User is logged in, allow access to the route
//     next();
//   } else {
//     // User is not logged in, redirect to the login page or any other page
//     next('/'); // Redirect to the login page
//   }
// };
// const requireAuth = (to, from, next) => {
//   const user = Cookies.get('user');
//   if (user) {
//     next();
//   } else {
//     next('/')
//   }
// }

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/nadzornaPlosca',
    name: 'nadzornaPlosca',
    component: DashboardView,
    // beforeEnter: requireAuth
  },
  {
    path: '/pregledUr',
    name: 'pregledUr',
    component: TimeTrackingView,
    // beforeEnter: requireAuth
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    // beforeEnter: requireAuth
  },
  {
    path: '/404',
    component: NotFoundView,
    // beforeEnter: requireAuth
  },
  {
    path: '/error',
    component: GeneralErrorView,
    // beforeEnter: requireAuth
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === '/') {
    store.commit('hideNavbar');
  } else {
    store.commit('showNavbar');
  }
  next();
})

export default router
