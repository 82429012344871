<template>
  <div class="nav-bar">
    <div style="font-weight: bold">
      <img src="../assets/logo.png" alt="logo" width="250" height="30" />
    </div>
    <div class="nav-name" v-if="!isNarrow">
      <span
        >Pozdravljeni, <b>{{ useername }}</b></span
      >
      |
      <span class="navbars">
        <router-link class="navbara" to="/nadzornaPlosca"
          >Prijava ur</router-link
        >
      </span>
      |
      <span class="navbars">
        <router-link class="navbara" to="/pregledUr">Pregled ur</router-link>
      </span>
      |
      <span class="navbars" @click="logout">
        <router-link class="navbara" to="/logout"> Odjava </router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MenuIcon from "vue-material-design-icons/Menu";
import Cookies from "js-cookie";

export default {
  components: {
    MenuIcon,
  },
  data() {
    return {
      user: "",
      isNarrow: false,
      usser: "",
      username: [],
      useername: "",
    };
  },
  computed: {
    ...mapState(["isNavbarVisible"]),
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted() {
    window.addEventListener("resize", this.checkWidth);
    this.checkWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkWidth);
  },
  methods: {
    checkWidth() {
      this.isNarrow = window.innerWidth < 768; // Adjust the width as per your requirement
    },
    toggleMenu() {
      this.$refs.slideMenu.toggleMenu();
    },
    logout() {
      this.isOpen = !this.isOpen;
      this.$store.dispatch("logout");
      this.$router.push("/");
      Cookies.remove("user");
    },
  },
  created() {
    // const storedUser = sessionStorage.getItem("user");
    // if (storedUser) {
    //   const userObject = JSON.parse(storedUser);
    //   if (userObject.username) {
    //     this.user = userObject.username;
    //   }
    // } else {
    //   this.$router.push("/");
    // }
    const userCookie = Cookies.get("user");
    if (userCookie !== undefined) {
      const user = JSON.parse(userCookie);
      this.useername = user.username;
    } else {
      this.$router.push("/");
    }
  },
};
</script>

<style>
.nav-bar {
  justify-content: space-between;
  display: flex;
  flex: 1;
}

.navbara {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
}

.btn {
  border: 1px solid #333;
  border-radius: 10px;
}

.nav-name {
  top: 10;
  right: 10;
}
</style>