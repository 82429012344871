import { createStore } from 'vuex';

export default createStore({
  state: {
    user: null, // Initialize the user as null (not logged in)
    isNavbarVisible: true
  },
  getters: {
    getUser: (state) => state.user,
    isLoggedIn: (state) => !!state.user,
  },
  mutations: {
    hideNavbar(state) {
      state.isNavbarVisible = false;
    },
    showNavbar(state) {
      state.isNavbarVisible = true;
    },
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  actions: {
    login({ commit }, user) {
      // Perform the login logic (e.g., validate credentials)
      // If successful, commit the 'setUser' mutation
      commit('setUser', user);

      // Optionally, save the user to session storage
      sessionStorage.setItem('user', JSON.stringify(user));
    },
    logout({ commit }) {
      // Clear the user from state
      commit('clearUser');

      // Optionally, remove the user from session storage
      sessionStorage.removeItem('user');
    },
    checkSessionStorage({ commit }) {
      // Check if a user is stored in session storage on app start
      const storedUser = JSON.parse(sessionStorage.getItem('user'));
      if (storedUser) {
        // If a user is found in session storage, set it in state
        commit('setUser', storedUser);
      }
    },
  },
  modules: {},
});
