<template>
  <div class="dashboard-container">
    <div v-if="deleteItem" class="deleteItem">
      <div class="con">
        <div class="row">
          <div class="col" style="max-width: 500px">
            <h2>Brisanje vnosa</h2>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="col">
            <h4>Ste prepričani da želite izbrisati ta vnos?</h4>
          </div>
        </div>
        <div class="row">
          <div class="col" style="display: flex; justify-content: space-evenly">
            <button class="btn btn-warning" @click="deleteIt">Da</button>
            <button class="btn btn-secondary" @click="deleteItem = !deleteItem">
              Ne
            </button>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!isNarrow">
      <div class="row">
        <div class="col-2">
          <div class="registracija">
            <h2>Pregled ur</h2>
          </div>
        </div>
        <div class="col-8">
          <div class="row" style="margin-bottom: 10px">
            <div class="col" style="text-align: left">
              <label for="month">Mesec:</label>
              <select class="form-select" v-model="selectedMonth" id="month">
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="index + 1"
                >
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="col" style="text-align: left">
              <label for="year">Leto:</label>
              <select class="form-select" v-model="selectedYear" id="year">
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
            <div class="col" style="vertical-align: bottom">
              <br />
              <div @click="getUserHours" class="buttn pridobi">
                Pridobi vnose
              </div>
            </div>
            <div class="col" style="text-align: center">
              <br />
              <div @click="exportToPdf" class="buttn pridobi">Izpis v pdf</div>
            </div>
          </div>
          <div class="row" id="tableToPdf">
            <div class="col">
              <p>{{ formattedDate }}</p>
              <div class="cards-container">
                <template v-if="items.length > 0">
                  <div
                    v-for="item in items"
                    :key="item.id"
                    class="card-wrapper"
                  >
                    <div class="card">
                      <div
                        class="card-header"
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <h2>{{ item.types }}</h2>
                        <button class="btn" @click="setItem(item)">x</button>
                      </div>
                      <div class="card-body">
                        <p>
                          <strong>Čas:</strong> {{ item.od }} - {{ item.do }}
                        </p>
                        <p><strong>Datum:</strong> {{ item.datum }}</p>
                        <p><strong>Skupaj:</strong> {{ item.ure }}</p>
                      </div>
                      <div
                        v-if="isFutureDate(item.datum)"
                        class="overlay"
                      ></div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <p>Ni aktivnih vnosov</p>
                </template>
              </div>
              <!-- <table class="table table-bordered table-striped" id="tableToPdf">
                <thead>
                  <tr>
                    <th>Dan</th>
                    <th>Datum</th>
                    <th>Od</th>
                    <th>Do</th>
                    <th>Vrsta</th>
                    <th>Ure</th>
                    <th>Ure skupaj</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="items.length > 0">
                    <tr v-for="item in items" :key="item.id">
                      <td>{{ getDayOfWeek(item.datum) }}</td>
                      <td>{{ item.datum }}</td>
                      <td>{{ item.od }}</td>
                      <td>{{ item.do }}</td>
                      <td>{{ item.types }}</td>
                      <td>{{ item.ure }}</td>
                      <td>{{ item.skupneUre }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="7">Ni aktivnih vnosov</td>
                    </tr>
                  </template>
                </tbody>
              </table> -->
            </div>
          </div>
        </div>
        <div class="col-2" style="text-align: left">
          <div class="stanje">
            <h2>Stanje ur</h2>
            <div class="info-row">
              <p>Ta mesec</p>
              <p class="number">{{ ureSkup }} / {{ totalMonth }}</p>
            </div>
            <!-- <div class="info-row">
              <p>Dopust</p>
              <p class="number">{{ dopust }}</p>
            </div> -->
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col">
          <div class="registracija">
            <h2>Pregled ur</h2>
          </div>
        </div>
        <div class="col" style="text-align: left">
          <div class="stanje">
            <h2>Stanje ur</h2>
            <div class="info-row">
              <p>Ta mesec</p>
              <p class="number">{{ ureSkup }} / {{ totalMonth }}</p>
            </div>
            <!-- <div class="info-row">
              <p>Dopust</p>
              <p class="number">{{ dopust }}</p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="row" style="margin-bottom: 10px">
            <div class="col" style="text-align: left">
              <label for="month">Mesec:</label>
              <select class="form-select" v-model="selectedMonth" id="month">
                <option
                  v-for="(month, index) in months"
                  :key="index"
                  :value="index + 1"
                >
                  {{ month }}
                </option>
              </select>
            </div>
            <div class="col" style="text-align: left">
              <label for="year">Leto:</label>
              <select class="form-select" v-model="selectedYear" id="year">
                <option v-for="year in years" :key="year" :value="year">
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col" style="vertical-align: bottom">
              <br />
              <div @click="getUserHours" class="buttn pridobi">
                Pridobi vnose
              </div>
            </div>
            <div class="col" style="text-align: center">
              <br />
              <div @click="exportToPdf" class="buttn pridobi">Izpis v pdf</div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="cards-container">
                <template v-if="items.length > 0">
                  <div
                    v-for="item in items"
                    :key="item.id"
                    class="card-wrapper"
                  >
                    <div class="card">
                      <div
                        class="card-header"
                        style="
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                        "
                      >
                        <h2>{{ item.types }}</h2>
                        <button class="btn" @click="setItem(item)">x</button>
                      </div>
                      <div class="card-body">
                        <p>
                          <strong>Čas:</strong> {{ item.od }} - {{ item.do }}
                        </p>
                        <p><strong>Datum:</strong> {{ item.datum }}</p>
                        <p><strong>Skupaj:</strong> {{ item.ure }}</p>
                      </div>
                      <div
                        v-if="isFutureDate(item.datum)"
                        class="overlay"
                      ></div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <p>Ni aktivnih vnosov</p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import { connIp } from "../store/conn";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      username: [],
      zacni: true,
      items: [],
      ureSkup: "",
      dopust: "",
      showSuccessMessage: false,
      currentDate: new Date(),
      months: [
        "Januar",
        "Februar",
        "Marec",
        "April",
        "Maj",
        "Junij",
        "Julij",
        "Avgust",
        "September",
        "Oktober",
        "November",
        "December",
      ],
      years: [],
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      currMonth: new Date().getMonth() + 1,
      currYear: new Date().getFullYear(),
      isNarrow: false,
      formattedDate: "",
      err: "",
      setDeleteItem: [],
      deleteItem: false,
      totalMonth: "",
      connIp: connIp,
    };
  },
  computed: {
    currentMonth() {
      this.selectedMonth = this.currentDate.getMonth() + 1;
      return this.currentDate.getMonth() + 1;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkWidth);
  },
  methods: {
    setItem(item) {
      this.setDeleteItem = JSON.parse(JSON.stringify(item));
      this.deleteItem = !this.deleteItem;
    },
    deleteIt() {
      var data = this.setDeleteItem;
      fetch(this.connIp + "deleteIt.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((resp) => {
          this.deleteItem = !this.deleteItem;
          this.getUserHours();
        })
        .catch((error) => (this.err = error));
    },
    isFutureDate(date) {
      const today = new Date();
      const parts = date.split("-");
      const itemDate = new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
      return itemDate > today;
    },
    getFormatedDate() {
      const daysOfWeek = [
        "Nedelja",
        "Ponedeljek",
        "Torek",
        "Sreda",
        "Četrtek",
        "Petek",
        "Sobota",
      ];
      const today = new Date();
      const dayOfWeek = daysOfWeek[today.getDay()];
      const dayOfMonth = today.getDate();
      const month = this.months[today.getMonth()];
      return `${dayOfWeek}, ${dayOfMonth}. ${month}`;
    },
    checkWidth() {
      this.isNarrow = window.innerWidth < 920; // Adjust the width as per your requirement
    },
    getUserHours() {
      fetch(
        this.connIp +
          `userHours.php?userId=${this.username.id}&status=${
            this.selectedMonth + "-" + this.selectedYear
          }`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.length > 0) {
            this.items = data.reverse();
            this.calcHours(data);
            this.getMonthHours();
          } else {
            this.items = data;
            this.calcHours(data);
            this.getMonthHours();
          }
        })
        .catch((error) => (this.err = error));
    },
    calcHours(data) {
      if (data.length > 0) {
        var ure = 0;
        var minute = 0;

        for (let i = 0; i < data.length; i++) {
          // Check if skupneUre is null or in '00:00' format
          if (data[i].ure === null) {
            data[i].ure = "00:00"; // If null, set a default value
          }

          // Split '00:00' into hours and minutes
          const [hours, minutes] = data[i].ure.split(":");

          // Convert hours and minutes to integers
          const hoursInt = parseInt(hours, 10);
          const minutesInt = parseInt(minutes, 10);

          // Perform calculations
          ure += hoursInt;
          minute += minutesInt;

          // Convert total minutes back to hours and minutes
          ure += Math.floor(minute / 60);
          minute %= 60;

          // Convert back to string and pad with zeros if necessary
          const updatedHours = String(ure).padStart(2, "0");
          const updatedMinutes = String(minute).padStart(2, "0");

          // Combine back into '00:00' format
          this.ureSkup = `${updatedHours}:${updatedMinutes}`;
        }
      } else {
        this.ureSkup = "00:00";
      }
    },
    getDayOfWeek(dateString) {
      const daysOfWeek = [
        "Nedelja",
        "Ponedeljek",
        "Torek",
        "Sreda",
        "Četrtek",
        "Petek",
        "Sobota",
      ];
      const dateParts = dateString.split("-");
      const day = parseInt(dateParts[0]);
      const month = parseInt(dateParts[1]) - 1; // Month is zero-based
      const year = parseInt(dateParts[2]);

      const date = new Date(year, month, day);
      const dayOfWeek = date.getDay();

      return daysOfWeek[dayOfWeek];
    },
    exportToPdf() {
      const resultsContainer = document.getElementById("tableToPdf");
      html2pdf(resultsContainer);
    },
    reloadWindow() {
      window.location.reload();
    },
    getMonthHours() {
      const data = {
        month: this.selectedMonth,
        year: this.selectedYear,
      };
      fetch(
        this.connIp + `getMonthHours.php?month=${data.month}&year=${data.year}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((resp) => resp.json())
        .then((data) => {
          this.totalMonth = data.totalHours;
        })
        .catch((error) => {
          this.err = error;
        });
    },
  },
  created() {
    const user = Cookies.get("user");
    if (user !== undefined) {
      this.username = JSON.parse(user);
    } else {
      this.$router.push("/");
    }
    this.getUserHours();
    this.getMonthHours();
  },
  mounted() {
    // Assuming you want to populate years dynamically
    const currentYear = new Date().getFullYear();
    for (let year = currentYear + 2; year >= currentYear - 1; year--) {
      this.years.push(year);
    }

    // Set initial values
    this.selectedMonth = new Date().getMonth() + 1; // January is 0 in JavaScript
    this.selectedYear = currentYear;

    window.addEventListener("resize", this.checkWidth);
    this.checkWidth();
  },
};
</script>

<style scoped>
.dashboard-container {
  background-color: #f3f3f3;
  padding: 20px;
  min-height: 100vh;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.number {
  font-weight: bold;
  text-align: right;
}
.registracija,
.stanje {
  border: 1px solid rgb(131, 10, 131);
  border-radius: 10px;
  padding: 10px;
  background-color: white !important;
  flex: 1;
}

.buttn {
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: bold;
  transition: 0.3s;
}

.pridobi {
  border: 1px solid black;
  transition: 0.3s;
}
.pridobi:hover {
  background-color: rgba(85, 85, 85, 0.2);
}
.zacni {
  border: 2px solid #32c434;
  transition: 0.3s;
}

.zacni:hover {
  background-color: #32c43434;
}

.koncaj {
  border: 2px solid #ff5e5e;
  transition: 0.3s;
}

.koncaj:hover {
  background-color: #ff5e5e54;
}

.malica {
  border: 2px solid rgb(91, 91, 221);
  transition: 0.3s;
}

.malica:hover {
  background-color: rgba(91, 91, 221, 0.212);
}

.doma {
  border: 2px solid rgb(85, 85, 85);
  transition: 0.3s;
}

.doma:hover {
  background-color: rgba(85, 85, 85, 0.212);
}

.praznik {
  border: 2px solid salmon;
  transition: 0.3s;
}

.praznik:hover {
  background-color: rgba(250, 128, 114, 0.212);
}

.dopust {
  border: 2px solid rgb(189, 189, 51);
  transition: 0.3s;
}

.dopust:hover {
  background-color: rgba(189, 189, 51, 0.199);
}

.izredniDopust {
  border: 2px solid burlywood;
  transition: 0.3s;
}

.izredniDopust:hover {
  background-color: rgba(222, 184, 135, 0.212);
}

.vnaprej {
  border: 2px solid rgb(85, 85, 85);
  transition: 0.3s;
}

.vnaprej:hover {
  background-color: rgba(85, 85, 85, 0.212);
}

.card-header h2,
.stanje h2,
.registracija h2 {
  font-size: 1.4rem;
}
.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap between cards */
}

.card-wrapper {
  flex: 0 0 calc(33.33% - 10px); /* Adjust the width of each card wrapper (3 cards in a row) */
  max-width: calc(
    33.33% - 10px
  ); /* Adjust the max-width of each card wrapper */
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(190, 190, 190, 0.3); /* Red with transparency */
  /* Add any other styles for the overlay */
}

.card {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Add other card styles as needed */
}

.deleteItem {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(190, 190, 190, 0.8); /* Adjust opacity and color */
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.con {
  background-color: #fff;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  width: 500px;
}

@media only screen and (max-width: 920px) {
  .card-header h2,
  .stanje h2,
  .registracija h2 {
    font-size: 1.4rem;
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between cards */
  }

  .card-wrapper {
    flex: 0 0 calc(50% - 10px); /* Adjust the width of each card wrapper (2 cards in a row) */
    max-width: calc(50% - 10px); /* Adjust the max-width of each card wrapper */
  }

  .card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Add other card styles as needed */
  }
}

@media only screen and (max-width: 460px) {
  .card-header h2,
  .stanje h2,
  .registracija h2 {
    font-size: 1.4rem;
  }
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between cards */
  }

  .card-wrapper {
    flex: 0 0 100%; /* Each card occupies the full width of the container */
    max-width: 100%; /* Set maximum width to 100% */
  }

  .card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Add other card styles as needed */
  }
}
</style>