<template>
  <div class="login-container">
    <div class="cont" v-if="register">
      <div class="registration-container">
        <div class="register">
          <div class="registration-form">
            <div
              style="
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
              "
            >
              <h1>Registracija</h1>
              <button @click="toggleRegister">x</button>
            </div>
            <h5>Ustvarite račun</h5>
            <form @submit.prevent="handleRegister">
              <div class="form-group">
                <label for="username">Uporabniško ime</label>
                <input
                  type="text"
                  id="username"
                  v-model="username"
                  placeholder="Vnesite uporabniško ime"
                  required
                />
              </div>
              <div class="form-group">
                <label for="email">E-pošta</label>
                <input
                  type="email"
                  id="email"
                  v-model="email"
                  placeholder="Vnesite vašo e-pošto"
                  required
                />
              </div>
              <div class="form-group">
                <label for="password">Geslo</label>
                <input
                  type="password"
                  id="password"
                  v-model="password"
                  placeholder="Vnesite geslo"
                  required
                />
              </div>
              <div class="form-group">
                <label for="confirm-password">Ponovno vnesite geslo</label>
                <input
                  type="password"
                  id="confirm-password"
                  v-model="confirmPassword"
                  placeholder="Ponovno vnesite geslo"
                  required
                />
              </div>
              <button type="submit">Registracija</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="login-form">
      <h1>Pozdravljeni</h1>
      <p>Vpis</p>
      <div class="errorMessage" v-if="errorMessage">
        Napačno uporabniško ime ali geslo
      </div>
      <form @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="username">Uporabniško ime</label>
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="Vnesite uporabniško ime"
            required
          />
        </div>
        <div class="form-group">
          <label for="password">Geslo</label>
          <input
            type="password"
            id="password"
            v-model="password"
            placeholder="Vnesite geslo"
            required
          />
        </div>
        <button type="submit">Vpis</button>
      </form>
      <br />
      <h5>Še nimate računa?</h5>
      <button @click="toggleRegister">Registrirajte se</button>
      <!-- <div class="forgot-password">
        <a href="#">Pozabljeno geslo?</a>
      </div> -->
    </div>
  </div>
</template>
  
  <script>
import { connIp } from "../store/conn";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      errorMessage: false,
      err: "",
      register: false,
      connIp: connIp,
    };
  },
  methods: {
    handleLogin() {
      this.errorMessage = false;
      fetch(this.connIp + "login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.username,
          password: this.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "success") {
            const user = {
              username: this.username,
              id: data.id,
              role: data.role,
              dopust: data.dopust,
            };
            Cookies.set("user", JSON.stringify(user), { expires: 7 });
            // Save the user data to session storage
            sessionStorage.setItem("user", JSON.stringify(user));
            // Redirect or perform other actions as needed
            this.$store
              .dispatch("login", user)
              .then(() => {
                this.$root.$emit("checkSessionStorage", user);
                // Redirect the user to the dashboard or any authorized page
                this.$router.push("/nadzornaPlosca");
              })
              .catch((error) => {
                // Handle login error
              });
          } else {
            this.errorMessage = true;
          }
        })
        .catch((error) => console.log(error));
    },
    toggleRegister() {
      this.register = !this.register;
    },
    handleRegister() {
      if (this.password !== this.confirmPassword) {
        // Passwords don't match, handle accordingly (show error message, etc.)
        return;
      }

      fetch(this.connIp + "register.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: this.username,
          email: this.email,
          password: this.password,
        }),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status === "success") {
            window.location.reload();
            // Registration successful, handle accordingly (redirect, show success message, etc.)
          } else {
            // Registration failed, handle accordingly (show error message, etc.)
          }
        })
        .catch((error) => {
          // Handle fetch error
          console.error(error);
        });
    },
  },
};
</script>
  
<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f3f3;
}

.login-form {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  text-align: center;
}

.login-form h1 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.login-form p {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin: 10px 0;
}

label {
  font-size: 14px;
  color: #666;
  text-align: left;
  width: 100%;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.forgot-password a {
  font-size: 14px;
  color: #007bff;
}

.errorMessage {
  font-size: 12px;
  color: red;
  font-style: italic;
}
.cont {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
}
.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f3f3;
}

.registration-form {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  text-align: center;
}

.registration-form h1 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.registration-form p {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin: 10px 0;
}

label {
  font-size: 14px;
  color: #666;
  text-align: left;
  width: 100%;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.login-link a {
  font-size: 14px;
  color: #007bff;
}
</style>