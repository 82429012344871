<template>
  <div class="registration-container">
    <div class="registration-form">
      <h1>Registracija</h1>
      <p>Ustvarite račun</p>
      <form @submit.prevent="register">
        <div class="form-group">
          <label for="username">Uporabniško ime</label>
          <input
            type="text"
            id="username"
            v-model="formData.username"
            placeholder="Vnesite uporabniško ime"
            required
          />
        </div>
        <div class="form-group">
          <label for="email">E-pošta</label>
          <input
            type="email"
            id="email"
            v-model="formData.email"
            placeholder="Vnesite vašo e-pošto"
            required
          />
        </div>
        <div class="form-group">
          <label for="password">Geslo</label>
          <input
            type="password"
            id="password"
            v-model="formData.password"
            placeholder="Vnesite geslo"
            required
          />
        </div>
        <div class="form-group">
          <label for="confirm-password">Ponovno vnesite geslo</label>
          <input
            type="password"
            id="confirm-password"
            v-model="formData.confirmPassword"
            placeholder="Ponovno vnesite geslo"
            required
          />
        </div>
        <button type="submit">Registracija</button>
      </form>
      <div class="login-link"></div>
    </div>
    <p>Že imate račun? <router-link to="/">Vpišite se</router-link></p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      formData: {
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    register() {
      // Perform registration logic here
      console.log("Registering with username:", this.formData.username);
      // You can send a request to your server for registration
    },
  },
};
</script>
  
  <style scoped>
.registration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f3f3;
}

.registration-form {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
  width: 300px;
  text-align: center;
}

.registration-form h1 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.registration-form p {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin: 10px 0;
}

label {
  font-size: 14px;
  color: #666;
  text-align: left;
  width: 100%;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  margin-top: 20px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.login-link a {
  font-size: 14px;
  color: #007bff;
}
</style>
  