<template>
    <div class="logout-container">
      <h1>Logout</h1>
      <p>Are you sure you want to log out of your account?</p>
      <div class="logout-buttons">
        <button @click="confirmLogout">Yes, Logout</button>
        <button @click="cancelLogout">Cancel</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      confirmLogout() {
        // Implement the logout logic here (e.g., clear user session)
        // Redirect the user to the login page or perform any necessary actions
        // For example, using Vue Router: this.$router.push('/login');
      },
      cancelLogout() {
        // Implement logic to cancel the logout, if needed
        // Redirect the user back to their profile or a relevant page
        // For example, using Vue Router: this.$router.push('/profile');
      },
    },
  };
  </script>
  
  <style scoped>
  .logout-container {
    background-color: #f3f3f3;
    padding: 20px;
    min-height: 100vh;
    text-align: center;
  }
  
  h1 {
    font-size: 24px;
    color: #333;
  }
  
  p {
    font-size: 16px;
    color: #333;
    margin: 20px 0;
  }
  
  .logout-buttons {
    display: flex;
    justify-content: center;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  </style>
  