<template>
  <div class="slide-menu" :class="{ open: isOpen }">
    <div class="vertical-menu">
      <span>Pozdravljeni, {{ username }}</span>
      <router-link @click="toggleMenu" to="/nadzornaPlosca"
        >Prijava ur</router-link
      >
      <router-link @click="toggleMenu" to="/pregledUr">Pregled ur</router-link>
      <span class="logout" @click="logout">Odjava</span>
    </div>
    <div class="close-button-container">
      <MenuClose class="close" @click="toggleMenu" />
    </div>
  </div>
</template>
    
  <script>
import MenuClose from "vue-material-design-icons/Close";
import Cookies from "js-cookie";

export default {
  components: {
    MenuClose,
  },
  data() {
    return {
      isOpen: false,
      user: [],
      username: "",
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    logout() {
      this.isOpen = !this.isOpen;
      this.$store.dispatch("logout");
      this.$router.push("/");
      Cookies.remove("user");
    },
  },
  created() {
    // const storedUser = sessionStorage.getItem("user");
    // if (storedUser) {
    //   const userObject = JSON.parse(storedUser);
    //   if (userObject.username) {
    //     this.user = userObject;
    //   }
    // } else {
    //   this.$router.push("/");
    // }
    const userCookie = Cookies.get("user");
    if (userCookie !== undefined) {
      const user = JSON.parse(userCookie);
      this.username = user.username;
    } else {
      this.$router.push("/");
    }
  },
};
</script>
    
  <style scoped>
/* CSS for the slide-in menu and animation */
.slide-menu {
  position: fixed;
  top: 0;
  left: -300px; /* Initially hidden to the left */
  width: 300px;
  transition: left 0.3s;
}
.slide-menu.open {
  left: 0; /* Slides in to the viewport */
}

.close {
  color: var(--background-color);
  transition: 0.3s;
}

.logout {
  cursor: pointer;
}
.close-button-container {
  position: absolute;
  right: 0;
  width: 50px;
  background-color: #333333;
  cursor: pointer;
  border-bottom-right-radius: 10px;
}
.vertical-menu {
  width: 250px;
  background-color: #333333;
  position: fixed;
  overflow-y: auto;
}

.vertical-menu * {
  border-bottom: 1px solid var(--background-color);
  text-decoration: none;
  font-size: 18px;
  color: #fff;
  display: block;
  transition: 0.3s;
  padding: 16px;
}

.vertical-menu *:hover {
  color: var(--text-color);
  background-color: var(--background-color);
}
</style>