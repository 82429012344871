<template>
    <div class="error-page general-error">
      <h1>Napaka!</h1>
      <p>Oops! Priško je do napake.</p>
      <p>Poskusite znova pozneje ali se obrnite na podporo, če težave ne odpravite ali težave ne odpravite.</p>
    </div>
  </template>
  
  <style scoped>
  /* Add styling specific to the general error page */
  </style>
  